/** @jsx jsx */
import { css } from "@emotion/react";
import ImgCta4 from "../images/lp/origem/cta-img.png";

export const cta1 = css`
  background: #F9F9F9;
  padding: 120px 0;
  overflow: hidden;
  position: relative;
  p {
    color: #312E2D;
  }
  img{
    padding: 0px;
    object-fit: cover;
    object-position: top;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0px 0;
    padding: 0px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 56px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 56px 0;
  }
`

export const cta2 = css`
  background: #00854E;
  padding: 0px;
  overflow: hidden;
  position: relative;
  h2{
    color: #fff;
  }
  p {
    color: #fff;
  }
  img{
    width: 100%;
    padding: 70px 50px 0px 0px;
   
    @media (max-width: 500px){
     padding: 0px 0px 0px 0px;
    background-color: #00854E;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0px 0;
    img {
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 56px 0;
    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 56px 0;
    img {
      width: 100%;
    }
  }

`

export const cta3 = css`
  background: #ffffff;
  padding: 180px 0;
  overflow: hidden;
  position: relative;
  p {
    color: #312E2D;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 56px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 56px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 56px 0;
  }
`

export const cta4 = css`
  background: url(${ImgCta4}) no-repeat center center / cover;
  padding: 120px 0;
  overflow: hidden;
  position: relative;
  font-family: "normalidad-variable", sans-serif;
  h4 {
    color: #ffffff;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    font-variation-settings: "wght" 130, "wght" 700;
    width: 100%;
    max-width: 730px;
    margin: auto;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 80px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 80px 0;
  }
`

export const cta5 = css`
  background: #00854E;
  padding: 50px 0px 50px 0px ;
  overflow: hidden;
  position: relative;
  h2{
    color: #FFF;
    padding-top: 90px;
  }
  p {
    color: #FFF;
  }
  img{
    padding: 0px 0px 0px 0px;
    object-fit: cover;
    object-position: top;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 0px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 56px 0;
  }
`

export const cta6 = css`
  background: #F9F9F9;
  padding: 60px 0;
  overflow: hidden;
  position: relative;
  p {
    color: #312E2D !important;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 56px;
    padding-top: 0px;
    img {
      width: 100%;
      margin-bottom: 56px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding-bottom: 56px;
    padding-top: 0px;
    img {
      width: 100%;
      margin-bottom: 56px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-bottom: 56px;
    padding-top: 0px;
    img {
      width: 100%;
      margin-bottom: 56px;
    }
  }
`

export const cta7 = css`
  background-color: #FFF;
  padding: 70px 0;
  overflow: hidden;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  p {
    color: var(--black, #312E2D);
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    width: 100%;
    max-width: 730px;
    margin: auto;
    span {
      font-weight: 700;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 80px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 80px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 80px 0;
  }
`

export const imgBox = css`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  /* margin: auto 0; */
  width: 50%;
  z-index: 10;
  div h2 {
    color: #fff;
  }
  img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
`

export const imgBoxL = css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  /* margin: auto 0; */
  width: 50%;
  z-index: 10;
  div h2 {
    color: #fff;
  }
  img {
    width: 100%;
    height: 100%;
  }
`

export const mobileImg = css`
  img {
    width: 100%;
  }
`

export const nomeBertoni = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const boxContent = css`
  /* padding-left: 90px; */
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 0px;
    width: 100%;
    max-width: 510px;
    padding: 20px 0px 100px 0px;
    @media (max-width: 500px){
     padding: 20px 0px 50px 0px;
    }
  }
  h2 {
    width: 100%;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    padding: 100px 0px 0px 0px;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
    margin-bottom: 40px;
    @media (max-width: 500px){
     padding: 50px 0px 0px 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
background-color: #00854E ;
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`
