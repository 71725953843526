/** @jsx jsx */
import React, { useState, useEffect, useContext } from "react";
import { LogoImage, NavLinks } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import { Link } from "gatsby";
import { jsx } from '@emotion/react'

import Instagram from '../assets/images/lp/icons/instagram-header.svg'
import Facebook from '../assets/images/lp/icons/facebook-header.svg'
import Youtube from '../assets/images/lp/icons/youtube-header.svg'

import { 
  header,
  logo,
  mainMenu,
  submenu,
  btnAgendeSuaVisita, 
  socialIcon,
}  from '../assets/styles/Header.styles'

const HeaderOne = () => {
  const [sticky, setSticky] = useState(false);
  const { searchStatus, updateSearchStatus } = useContext(SearchContext);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const handleSearchClick = e => {
    e.preventDefault();
    updateSearchStatus(!searchStatus);
  };
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <header css={header}
      className={`${
        true === sticky ? "fixedHeader animated flipInX" : null
      }`}
      id="header"
    >
      
      <Container>
        <Row>
          <Col lg={3} md={3} sm={3}>
            <div css={logo}>
              <Link to="/">
                <img src={LogoImage.light} alt="" />
              </Link>
            </div>
          </Col>
          <Col lg={5} md={5} sm={6} className="d-none d-lg-block ">
            <nav css={mainMenu} className="text-left">
              <ul>
                {NavLinks.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? "menu-item-has-children"
                          : ""
                      }`}
                    >
                      <Link to={links.url}>{links.name}</Link>
                      {undefined !== links.subItems ? (
                        <ul css={submenu} className="sub-menu">
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <div className="text-right" style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <a href="instagram-link"><img src={Instagram} /></a>
                <a css={socialIcon} href="facebook-link"><img src={Facebook} /></a>
                <a href="youtube-link"><img src={Youtube} /></a>
              </div>
              <Link css={btnAgendeSuaVisita} to='#contatoUnidades' style={{ marginLeft: 34 }}>
                Agende sua visita
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderOne;
