/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import imgWhats from '../assets/images/lp/unidades/icons/whats.svg';
import imgInsta from '../assets/images/lp/unidades/icons/insta.svg';
import imgMap from '../assets/images/lp/unidades/icons/mapa.svg';
import { actionsImgIcons, containerMapa } from '../assets/styles/UnidadeMapa.styles';
import { Link } from 'gatsby';

const UnidadeMapa = ({title,paragraph,address,linkMap, numberFone, instagram, urlMap}) => {
  return (
    <section css={containerMapa}>
        <Container data-aos="fade-down">
            <Row className="d-flex justify-content-center">
                <Col className="address text order-2 order-md-1" xs={12} md={5}>
                    <h1 >{title}</h1>
                    <p className='paragraph'  style={{ color: "#312E2D",paddingTop:'30px' }}>{paragraph}</p>
                    <div className="d-flex align-items-center ">
                        <a css={actionsImgIcons} href={urlMap} target="_blank" className='mt-1'> <img  src={imgMap} /> {address} </a>
                    </div>
                    <div className="d-flex align-items-center instaLink">
                        <a css={actionsImgIcons} href={instagram} target="_blank" className='mt-1'> <img  src={imgInsta} /> Bertoni Elos </a>
                    </div>
                    <div className="d-flex align-items-center pb-2 ">
                        <a css={actionsImgIcons} href={numberFone ? `https://wa.me/${numberFone}` : "https://wa.me/554588442900" } target="_blank" className='mt-1'> <img src={imgWhats} /> Fale Conosco {numberFone ? "(45) 8842-4675" : "(45) 98844-2900" } </a>
                    </div>
                    <div className='btnMobile '>
                        <Link to="/#contatoUnidades">
                            <Button
                            variant="success"
                            >
                                Agende uma visita
                            </Button>
                        </Link>
                    </div>
                </Col>
                <Col className="address map order-1 order-md-2" xs={12} md={5}>
                    <iframe
                    style={{ border: "none", height: "450px", width: "100%" }}
                    src={linkMap}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    />
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default UnidadeMapa
