/** @jsx jsx */
import { jsx } from '@emotion/react'

import { 
  sobreBanner,
  bannerContent
}  from '../assets/styles/PageBannerStore.styles'
import { useEffect, useState } from 'react';

const SobreBanner = () => {
     const [isMobile, setIsMobile] = useState(false)
      useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 600)
        }
        if (typeof window !== 'undefined') {
          setIsMobile(window.innerWidth < 600)
          window.addEventListener('resize', handleResize)
        }
        return () => {
          if (typeof window !== 'undefined') {
            window.removeEventListener('resize', handleResize)
          }
        }
      }, [])
  return (
    <section css={sobreBanner}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div css={bannerContent} className="banner_content text-center">
              <h1>{isMobile ? 'Nossas Lojas' : 'Nossas Lojas Virtuais'}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SobreBanner;
