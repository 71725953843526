/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "react-modal-video/css/modal-video.min.css"
import { jsx } from '@emotion/react'
import ImgEnsinoFundamental from '../assets/images/lp/unidades/Banner/bannerJardinEncantado/jardinEncantado.png'

import { 
  FundamentalContent,
  imgBox,
  boxContent,
  DDesktop,
  DMobile,
  imgSect
}  from '../assets/styles/bannerCentroMedianeira.styles'

const CentroMedianeiraBanner = () => {
  return (
    <Fragment>
      <div css={DMobile}>
        <img css={imgSect} src={ImgEnsinoFundamental} alt='/' />
      </div>
      <section css={FundamentalContent} id='ensino-fundamental'>
        <Container>
          <Row>
            <Col
              data-aos-offset='300'
              data-aos='fade-down'
              data-aos-easing='ease-in-out'
              lg={6} sm={12}>
              <div css={boxContent}>
                <h2>Ensino de excelência, 
                da Educação Infantil às turmas Pré-Vestibulares</h2>
                 <p>Somos especializados em educação de alto desempenho. Somos a unidade de ensino da Rede Bertoni que une 30 anos de tradição ao método de alto desempenho.</p>
                 <p>
                 Somos uma rede internacional de educação que atua no Sul do País (PR, RS) e no Paraguai.
                 </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div
         data-aos-offset='300'
         data-aos='fade-down'
         data-aos-easing='ease-in-out'
         css={[imgBox, DDesktop]}>
          <img src={ImgEnsinoFundamental} alt='/' />
        </div>
      </section>
      
    </Fragment>
  );
};

export default CentroMedianeiraBanner;
