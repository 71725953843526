import React, { useEffect } from "react";
import Layout from "@/components/layout";
import Footer from "@/components/footer";
import SliderAprovados from "@/components/team-carousel";
import OrigemContent from "@/components/ContentSection";
import MenuHeader from "@/components/header-two";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderHeader from "@/components/SliderHeader";
import EstruturaContent from "@/components/EstruturaContent";
import ContatosHome from "@/components/ContatosHome";
import ElosContent from "@/components/ElosHome";
import Segments from "@/components/Segments";
import StoreCarousel from "@/components/StoreCarousel";

const Index = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Home">
          <MenuHeader />
          <SliderHeader /> 
          <OrigemContent />
          <ElosContent />
          <Segments />
          <SliderAprovados />
          <EstruturaContent />
          <StoreCarousel />
          <ContatosHome />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default Index;
