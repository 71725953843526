/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Col, Container, Image, Row } from 'react-bootstrap';
import { cardStore, cardStoreBoulevard, cardStorecentro, containerStore, containerTexts, imagebackground } from '../assets/styles/SectionStore.styles';
import { Link } from 'gatsby';
import medianeira from '../assets/images/lp/store/sectionStore/centro.png'
import boulevard from '../assets/images/lp/store/sectionStore/boulevard.png'

const SobreBanner = () => {
     
  return (
    <section >
       <Container css={containerStore} className='d-flex justify-content-center'>
            <Row>
                <Col xs={12} md={6} lg={6} css={cardStorecentro}>
                    <div className='gradiente'>
                            <Image css={imagebackground} src={medianeira}  />
                        <div css={containerTexts}>
                            <h1>Bertoni Elos</h1>
                            <span>Medianeira</span>
                            <Link className='unitsLinks' to='https://lojamedianeira.bertonielos.com.br/'>Visitar Loja</Link>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={6} css={cardStoreBoulevard}>
                    <div className='gradiente'>
                            <Image css={imagebackground} src={boulevard}  />
                        <div css={containerTexts}>
                            <h1>Bertoni Elos</h1>
                            <span>Boulevard</span>
                            <Link className='unitsLinks' to='https://loja.bertonielos.com.br/'>Visitar Loja</Link>
                        </div>
                    </div>
                </Col>
            </Row>
       </Container>
    </section>
  );
};

export default SobreBanner;
