/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Col, Container, Image, Row } from "react-bootstrap";
import imgCentro1 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro1.png';
import imgCentro2 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro2.png';
import imgCentro3 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro3.png';
import imgCentro4 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro4.png';
import imgCentro5 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro5.png';
import imgCentro6 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro6.png';
import imgCentro7 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro7.png';
import imgCentro8 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro8.png';
import imgCentro9 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro9.png';
import imgCentro10 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro10.png';
import imgCentro11 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro11.png';
import imgCentro12 from '../assets/images/lp/unidades/listImages/listImgsCentroMedianeira/centro12.png';

import { containerListDesktop, containerListMobile, rowList } from '../assets/styles/ListImagesElosCenter.styles';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';


function ListImagesElosCenter() {
   const images = [imgCentro1,imgCentro2,imgCentro3,imgCentro4,imgCentro5,imgCentro6,imgCentro7,imgCentro8,imgCentro9,imgCentro10,imgCentro11,imgCentro12]
  return (
    <section>
        <Container  data-aos="fade-down"  css={containerListDesktop}>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgCentro1} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro2} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro3} rounded  />
                </Col>
            </Row>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgCentro4} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro5} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro6} rounded  />
                </Col>
            </Row>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgCentro7} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro8} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro9} rounded  />
                </Col>
            </Row>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgCentro10} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro11} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro12} rounded  />
                </Col>
            </Row>
        </Container>
        <Container  data-aos="fade-down"  css={containerListMobile}>
            <Row css={rowList}>
                <Col>
                    <Swiper
                     effect='fade'
                     slidesPerView={'auto'}
                     spaceBetween={0}
                     loop={true}
                     pagination={{
                         clickable: true,
                     }}
                     autoplay={{
                         delay: 5000,
                         disableOnInteraction: false,
                       }}
                     mousewheel={true}
                     modules={[Autoplay, Mousewheel, Pagination, Navigation]}
                        className="mySwiper"
                    >
                     {   
                     images.map((img) => {
                        return (
                            <SwiperSlide>
                                <Image src={img} />
                            </SwiperSlide>
                        )
                     })
                     }
                    </Swiper>
                </Col>
            </Row>
        </Container>
    </section>
  );
}

export default ListImagesElosCenter;
