/** @jsx jsx */
import { css } from '@emotion/react'

export const btnKnowUniforms = css`
  margin-bottom: 100px;
  padding: 16px 32.5px;
  height: 52px;
  background: #4BE66A;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #312E2D;
  font-variation-settings: "wght" 700;
  :hover {
    background: #00854E;
    color: #ffffff;
    text-decoration: none;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
    text-decoration: none;
  }
  @media (max-width: 992px){
    font-size: 10px;
    }
    @media (max-width: 700px){
    font-size: 16px;
    }
`


export const carouselStoreSwipe = css` 
  background: #F9F9F9;
  overflow: hidden;
  h1{
    color:  #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    padding-top: 48px;
    @media (max-width: 1200px){
      padding-top: 45px;
    }
    @media (max-width: 992px){
      padding-top: 0px;
    }
  }
  
  p{
    color: #9D9C9B;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding-bottom: 48px;
    @media (max-width: 1200px){
      padding-bottom: 43px;
    }
    @media (max-width: 992px){
      padding-bottom: 26px;
    }
  }

  a{
    padding-left: 32px;
    @media (max-width: 1200px){
      
    }
  }

  .slideCarrousel{
    padding-bottom: 50px;
  }

  .containerTexts{
    padding-left: 24px;
    @media (max-width: 700px){
      padding-left: 24px;
    }
  }

  .containerImg{
    max-width: 390px;
    @media (max-width: 1200px){
      max-width: 300px;
    }
    @media (max-width: 992px){
      max-width: 250px;
    }
    @media (max-width: 600px){
      max-width: 450px;
    }
  }

  .itemImg{
    width: 100%;
    height: auto;
  }

  .swiper {
    grid-area: slider;
    width: 170%;
    height: auto;
    padding-right: 36px;
    
  }
   
  .swiper-slide {
    width: 390px;
    @media (max-width: 1200px){
      width: 300px;
    }
    @media (max-width: 992px){
      max-width: 250px;
      padding-right: 20px;
    }
    @media (max-width: 600px){
      max-width: 450px;
      padding-left: 12px;
    }
  }

  .swiper-slide-prev{
    width: 390px;
    @media (max-width: 1200px){
      width: 300px;
    }
    @media (max-width: 992px){
      max-width: 250px;
    }
    @media (max-width: 600px){
      max-width: 450px;
    }
  }

  .swiper-slide-next{
    width: 390px;
    @media (max-width: 1200px){
      width: 300px; 
    }
    @media (max-width: 992px){
      max-width: 250px;
    }
    @media (max-width: 600px){
      max-width: 450px;
    }
  }
 
  .swiper-slide-active{
    width: 390px;
    @media (max-width: 1200px){
      width: 300px;
    }
    @media (max-width: 992px){
      max-width: 250px;
    }
    @media (max-width: 600px){
      max-width: 450px;
    }
  }

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    display: none;
}
`