/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import { Link } from 'gatsby'
import { Fragment, useState } from 'react'
import EducacaoInfantil from '../assets/images/lp/segments/educacao-infantil.png'
import IniciaisEFAI from '../assets/images/lp/segments/efa-iniciais.png'
import FinaisEFAI from '../assets/images/lp/segments/efa-finais.png'
import EnsinoMedio from '../assets/images/lp/segments/ensino-medio.png'
import PreVestibular from '../assets/images/lp/segments/pre-vestibular.png'

import {
  SegmentosSection,
  mb100,
  btnSaibaMais,
  DMobile,
  DDesktop,
} from '../assets/styles/ContentSection.styles'
import AccordionSection from '../components/CustomAccordion'
import { accordion1, accordion2, accordion3, accordion4 } from '@/data'

const ContentSection = () => {
  const [activeAccordion1, setActiveAccordion1] = useState(null)
  const [activeAccordion2, setActiveAccordion2] = useState(null)
  const [activeAccordion3, setActiveAccordion3] = useState(null)
  const [activeAccordion4, setActiveAccordion4] = useState(null)
  const toggleAccordion1 = (eventKey) => {
    setActiveAccordion1(activeAccordion1 === eventKey ? null : eventKey)
    setActiveAccordion2(null)
    setActiveAccordion3(null)
    setActiveAccordion4(null)
  }
  const toggleAccordion2 = (eventKey) => {
    setActiveAccordion2(activeAccordion2 === eventKey ? null : eventKey)
    setActiveAccordion1(null)
    setActiveAccordion3(null)
    setActiveAccordion4(null)
  }
  const toggleAccordion3 = (eventKey) => {
    setActiveAccordion3(activeAccordion3 === eventKey ? null : eventKey)
    setActiveAccordion2(null)
    setActiveAccordion1(null)
    setActiveAccordion4(null)
  }
  const toggleAccordion4 = (eventKey) => {
    setActiveAccordion4(activeAccordion4 === eventKey ? null : eventKey)
    setActiveAccordion3(null)
    setActiveAccordion2(null)
    setActiveAccordion1(null)
  }
  
  return (
    <Fragment>
      <section css={SegmentosSection}>
        {/* <Container>
          <img
            css={DMobile}
            src={ImgOrigem}
            alt='estudante escrevendo'
            style={{ width: '100%' }}
          />
        </Container> */}
        <Container>
          <img
            css={DMobile}
            src={EducacaoInfantil}
            alt='estudante escrevendo'
            style={{ width: '100%' }}
          />
          <Row css={mb100}>
            <Col className='d-flex align-content-center flex-wrap' lg={6} md={6} sm={12}>
              <div className='box-segmento'>
                <h2>Elos que se formam desde a <span>educação infantil</span></h2>
                <h3>Medianeira</h3>
                <p><span>Somos especializados na primeira infância (0 a 5 anos) e acreditamos na força da educação do Jardim de Infância. Uma escola orientada a projetos, com mais de 20 eventos pedagógicos e socioemocionais.</span></p>
                <p id="efai">Educação Infantil e Integral - Unidade Jardim Encantado</p>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col css={DDesktop} lg={5} md={5} sm={12}>
              <img
                src={EducacaoInfantil}
                alt='estudante escrevendo'
              />
            </Col>
          </Row>
        </Container>
        <Container >
          <img
            css={DMobile}
            src={IniciaisEFAI}
            alt='estudante escrevendo'
            style={{ width: '100%' }}
          />
          <Row  css={mb100}>
            <Col className='d-flex align-content-center flex-wrap' lg={6} md={6} sm={12}>
              <div className='box-segmento'>
                <h2>Ensino fundamental anos iniciais <span>(EFAI)</span></h2>
                <h3>Medianeira</h3>
                <p id="efaf" >Uma proposta pedagógica inovadora e de resultados, orientada pelas melhores práticas de ensino do mundo.</p>
                <AccordionSection
                  eventKey={toString()}
                  callback={toggleAccordion1}
                  isOpen={activeAccordion1 === toString()}
                  title="Saiba Mais"
                  sizeTitle='20px'
                  mbDropDonw={16}           
                  >
                    <ul >
                    {accordion1.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )})}
                    </ul>
                </AccordionSection>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col css={DDesktop} lg={5} md={5} sm={12}>
              <img
                src={IniciaisEFAI}
                alt='estudante escrevendo'
              />
            </Col>
          </Row>
        </Container>
        <Container>
          <img
            css={DMobile}
            src={FinaisEFAI}
            alt='estudante escrevendo'
            style={{ width: '100%' }}
          />
          <Row css={mb100}>
            <Col className='d-flex align-content-center flex-wrap' lg={6} md={6} sm={12}>
              <div className='box-segmento'>
                <h2>Ensino fundamental anos finais<span>(EFAF)</span></h2>
                <h3>Medianeira e Foz do Iguaçu</h3>
                <p id="ensinoMedio">O Elos prepara os alunos para o sucesso acadêmico e profissional.</p>
                <AccordionSection
                  eventKey={toString()}
                  callback={toggleAccordion2}
                  isOpen={activeAccordion2 === toString()}
                  title="Saiba Mais"
                  sizeTitle='20px'  
                  mbDropDonw={16}           
                  >
                    <ul>
                    {accordion2.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )})}
                    </ul>
                </AccordionSection>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col css={DDesktop} lg={5} md={5} sm={12}>
              <img
                src={FinaisEFAI}
                alt='estudante escrevendo'
              />
            </Col>
          </Row>
        </Container>
        <Container  >
          <img
            css={DMobile}
            src={EnsinoMedio}
            alt='estudante escrevendo'
            style={{ width: '100%' }}
          />
          <Row css={mb100}>
            <Col className='d-flex align-content-center flex-wrap' lg={6} md={6} sm={12}>
              <div className='box-segmento'>
                <h2><span>Ensino médio</span> que prepara para as melhores universidades</h2>
                <h3>Medianeira e Foz do Iguaçu</h3>
                <p  id="preVest">O Bertoni Elos é o colégio que foi mais vezes primeiro lugar do ENEM e é quem mais aprova nas federais em Medianeira, Foz e toda a região.</p>
                <AccordionSection
                  eventKey={toString()}
                  callback={toggleAccordion3}
                  isOpen={activeAccordion3 === toString()}
                  title="Saiba Mais"
                  sizeTitle='20px'
                  mbDropDonw={16}
                  >
                    <ul>
                    {accordion3.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )})}
                    </ul>
                </AccordionSection>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col css={DDesktop} lg={5} md={5} sm={12}>
              <img
                src={EnsinoMedio}
                alt='estudante escrevendo'
              />
            </Col>
          </Row>
        </Container>
        <Container  >
          <img
            css={DMobile}
            src={PreVestibular}
            alt='estudante escrevendo'
            style={{ width: '100%' }}
          />
          <Row css={mb100}>
            <Col className='d-flex align-content-center flex-wrap' lg={6} md={6} sm={12}>
              <div className='box-segmento'>
                <h2><span>Pré-vestibular</span> que conecta seu talento à rede que mais aprova</h2>
                <h3>Medianeira e Foz do Iguaçu</h3>
                <p>Estude com o método 14x nº1 no ENEM!</p>
                <AccordionSection
                  eventKey={toString()}
                  callback={toggleAccordion4}
                  isOpen={activeAccordion4 === toString()}
                  title="Saiba Mais"
                  sizeTitle='20px'
                  mbDropDonw={16}
                  >
                    <ul>
                    {accordion4.map((item, index) => {
                      return (
                        <li key={index}>{item}</li>
                      )})}
                    </ul>
                </AccordionSection>
              </div>
            </Col>
            <Col lg={1} md={1} sm={12} />
            <Col css={DDesktop} lg={5} md={5} sm={12}>
              <img
                src={PreVestibular}
                alt='estudante escrevendo'
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default ContentSection
