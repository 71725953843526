/** @jsx jsx */
import { css } from "@emotion/react";
import bg8 from "../images/lp/store/bannerStoreDesktop.png";
import bgMobile from "../images/lp/store/bannerStoreMobile.png";

export const sobreBanner = css`
  position: relative;
  min-height: 430px;
  background: url(${bg8}) no-repeat center center / cover;
  padding-top: 224px;
  @media (max-width:600px){
    background: url(${bgMobile}) no-repeat center center / cover;
  }
`

export const turmaBanner = css`
  position: relative;
  min-height: 430px;
  background: url(${bg8}) no-repeat center center / cover;
  padding-top: 224px;
  @media (max-width:600px){
    background: url(${bgMobile}) no-repeat center center / cover;
  }
`

export const bannerContent = css`
  position: relative;
  h1 {
    font-weight: 400;
    font-size: 56px;
    line-height: 120%;
    color: #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
  }
  p{
    color:  #312E2D;
    text-align: center;
    font-family: "Normalidad VF", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
`
