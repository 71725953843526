/** @jsx jsx */
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, EffectFade } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import { SliderTwoData } from "@/data";
import { jsx } from '@emotion/react'
import imageMobile from '../assets/images/lp/slider-header/banner-headerMobile.png'; 
import {mainSlider}  from '../assets/styles/SliderOne.styles'
import { mainSliderTwo }  from '../assets/styles/SliderTwo.styles'

import { 
  sliderHeader,
  sliderheaderMobile,
}  from '../assets/styles/SliderHeader.styles'
import { Fragment } from "react";

SwiperCore.use([Autoplay, Navigation, EffectFade]);
const SliderTwo = () => {
  const mainSlideOptions = {
    slidesPerView: 1,
    loop: true,
    // effect: "fade",
    Autoplay: false,
    navigation: {
      nextEl: "#main-slider-next",
      prevEl: "#main-slider-prev",
    },
    
  };
  return (
    <Fragment>
          <section css={[mainSlider,mainSliderTwo]}>
              <Swiper {...mainSlideOptions}>
                {SliderTwoData.map(({ image, title, text, button }, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="image-layer"
                      style={{ backgroundImage: `url(${image})` }}
                    ></div>
                    <Container css={sliderHeader}>
                      <Row>
                        <Col lg={12} className="text-left">
                          <h1>{title}</h1>
                          <p>{text}</p>
                          <Link to={button.url}>
                            {button.label}
                          </Link>
                        </Col>
                      </Row>
                    </Container>
                  </SwiperSlide>
                ))}
                {/* <div className="swiper-button-prev" id="main-slider-prev">
                  <i className="fa fa-angle-left"></i>
                </div>
                <div className="swiper-button-next" id="main-slider-next">
                  <i className="fa fa-angle-right"></i>
                </div> */}
              </Swiper>
          </section>
          <section css={sliderheaderMobile}>
          <img
              src={imageMobile}
              alt='estudante escrevendo'
              style={{ width: '100%', paddingTop: '40px'}}
            />
          {SliderTwoData.map(({ image, title, text, button }, index) => (
                  <SwiperSlide key={index}>
                    <Container 
                    css={sliderHeader}
                    >
                      <Row style={{padding: '40px 0px 50px 0px'}}>
                        <Col lg={12} className="text-left">
                          <h1>{title}</h1>
                          <p>{text}</p>
                          <Link to={button.url}>
                            {button.label}
                          </Link>
                        </Col>
                      </Row>
                    </Container>
                  </SwiperSlide>
                ))}
          </section>
    </Fragment>
  );
};

export default SliderTwo;
