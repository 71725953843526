import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import SobreBanner from "@/components/SobreBanner"
import BannerJardinEncantado from "@/components/BannerJardinEncantado"
import ListImagesJardinEncantado from "@/components/ListImagesJardinEncantado"
import UnidadeMapa from "@/components/UnidadeMapa"
import HeaderOne from "@/components/header-two"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const JardimEncantadoPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Elos Jd. Encantado">
          <HeaderOne />
          <SobreBanner  paragraph="Educação Infantil e Integral" title="Elos Jd. Encantado"/>
          <BannerJardinEncantado />
          <ListImagesJardinEncantado />
          <UnidadeMapa urlMap="https://maps.app.goo.gl/QpzPNhWBa3DLkvqa9" instagram="https://www.instagram.com/bertoni.elos?igsh=YzljYTk1ODg3Zg==" title="Educação Infantil e Integral" paragraph="Unidade Jardim Encantado" address="Rua Iguaçu, 2055, Nazaré, Medianeira - PR" linkMap="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28859.87428386676!2d-54.11462446891499!3d-25.28792826973944!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f1534ab28d41f9%3A0xac0f67d6c9668760!2sEscola%20Jardim%20Encantado!5e0!3m2!1spt-BR!2sbr!4v1736119416494!5m2!1spt-BR!2sbr"/>
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default JardimEncantadoPage;
