/** @jsx jsx */
import { css } from "@emotion/react";

export const elos = css`
  padding: 80px 0;
  overflow: hidden;
  position: relative;

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 56px 0;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 56px 0;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 56px 0;
  }
`
export const mb56 = css`
  margin-bottom: 56px;
`

export const boxContent = css`
  text-align: center;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 56px;
    width: 100%;
    max-width: 445px;
    text-align: center;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
    margin-top: 16px;
    margin-bottom: 32px;
    text-align: center;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }
`

export const DDesktop = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
    img {
      width: 100%;
    }
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`
