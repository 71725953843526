/** @jsx jsx */
import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "react-modal-video/css/modal-video.min.css"
import { jsx } from '@emotion/react'
import ImgEnsinoFundamental from '../assets/images/lp/unidades/Banner/bannerBoulevard/boulevard.png'

import { 
  FundamentalContent,
  imgBox,
  boxContent,
  DDesktop,
  DMobile,
  imgSect
}  from '../assets/styles/bannerBoulevard.styles'

const BannerCentroMedianeira = () => {
  return (
    <Fragment>
      <div css={DMobile}>
        <img css={imgSect} src={ImgEnsinoFundamental} alt='/' />
      </div>
      <section css={FundamentalContent} id='ensino-fundamental'>
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div
                  data-aos-offset='300'
                  data-aos='fade-down'
                  data-aos-easing='ease-in-out'
                  css={boxContent}>
                 <p>O Elos Boulevard é uma escola pensada para gerar impacto e responder às demandas do século XXI. Acreditamos no poder transformador da educação como uma oportunidade de aproximar o saber do fazer. Entendemos o Bertoni Elos como território onde a aprendizagem ajuda a superar desafios e a buscar oportunidades de crescimento.</p>
                 <div className="nameList">
                   <span  >SALAS ESPECIAIS, LABORATÓRIOS E A MELHOR INFRAESTRUTURA DE FOZ</span>
                 </div>
                 <ul className="pt-3 a">
                  <li>Biblioteca</li>
                  <li>Salas organizadas em ilhas/ estações de aprendizagem</li>
                  <li>Os melhores professores do Sul</li>
                  <li>Campus Boulevard, referência nacional</li>
                 </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <div      
            data-aos-offset='300'
            data-aos='fade-down'
            data-aos-easing='ease-in-out' css={[imgBox, DDesktop]}>
          <img src={ImgEnsinoFundamental} alt='/' />
        </div>
      </section>
      
    </Fragment>
  );
};

export default BannerCentroMedianeira;
