/** @jsx jsx */
import { css } from '@emotion/react'

export const boxContent = css`
  padding: 80px 0px;
  padding-bottom: 0px;
  overflow: hidden;
  font-family: "normalidad-variable", sans-serif;
  h2 {
    font-weight: 600;
    font-size: 36px;
    font-style: normal;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    margin-bottom: 20px;
    font-variation-settings: "wght" 130, "wght" 600;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    color: #9D9C9B;
    margin-bottom: 56px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
  padding: 70px 0px 0px 0px;
    
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
  padding: 70px 0px 0px 0px;
    
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }

   @media (min-width: 768px) and (max-width: 990px) {
  padding: 70px 0px 0px 0px;
    
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }
  img{
    width: 100%;
  }
  /*@media (min-width: 991px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
  } */
`

export const mb100 = css`
  margin-bottom: 100px;
`

export const btnSaibaMais = css`
  margin-bottom: 100px;
  padding: 16px 32.5px;
  height: 52px;
  background: #4BE66A;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #312E2D;
  font-variation-settings: "wght" 700;
  :hover {
    background: #00854E;
    color: #ffffff;
    text-decoration: none;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
    text-decoration: none;
  }
`

export const DDesktop = css` 
overflow: hidden;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`

export const cta1 = css`
  background: #F9F9F9;
  padding: 160px 0;
  overflow: hidden;
  position: relative;
  margin-bottom: 80px;
  p {
    color: #312E2D;
    margin-bottom: 60px;
  }
  a {

  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0;
    padding-bottom: 80px;
    img {
      margin-bottom: 22px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    padding: 0;
    padding-bottom: 80px;
    img {
      margin-bottom: 22px;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding: 0;
    padding-bottom: 80px;
    img {
      margin-bottom: 22px;
    }
  }
  @media (max-width: 500px){
    margin-bottom: 0px;
  }
`

export const boxContent2 = css`
overflow: hidden;
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 60px;
    width: 100%;
    max-width: 445px;
  }
  h2 {
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-family: "normalidad-variable", sans-serif;
    font-variation-settings: "wght" 130, "wght" 600;
    margin-bottom: 40px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      margin-bottom: 40px;
      width: 100%;
    }
  }
`

export const imgBox = css`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  /* margin: auto 0; */
  width: 50%;
  z-index: 10;
  div h2 {
    color: #fff;
  }
  img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
  }
`

export const SegmentosSection = css`
  padding: 80px 0px;
  padding-bottom: 0px;
  font-family: "normalidad-variable", sans-serif;
  .box-segmento {

  }
  h2 {
    width: 100%;
    max-width: 475px;
    /* display: inline; */
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    align-items: center;
    color: #312E2D;
    font-variation-settings: "wght" 130, "wght" 600;
    span {
      color: #00854E;
    }
  }
  h3 {
    color: #312E2D;
    font-family: 'Montserrat', sans-serif;
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 40px;
    margin-bottom: 32px;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 150%;
    color: #312E2D;
    margin-bottom: 32px;
    width: 100%;
    max-width: 490px;
    span {
      color: #9D9C9B;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
  padding: 70px 0px 0px 0px;
    
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
  padding: 70px 0px 0px 0px;
    
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }

   @media (min-width: 768px) and (max-width: 990px) {
  padding: 70px 0px 0px 0px;
    
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }
  img{
    width: 100%;
  }
  /*@media (min-width: 991px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
  } */
`
