/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Col, Container, Image, Row } from "react-bootstrap";
import imgBoulevard1 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard1.png';
import imgBoulevard2 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard2.png';
import imgBoulevard3 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard3.png';
import imgBoulevard4 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard4.png';
import imgBoulevard5 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard5.png';
import imgBoulevard6 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard6.png';
import imgBoulevard7 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard7.png';
import imgBoulevard8 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard8.png';
import imgBoulevard9 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard9.png';
import imgBoulevard10 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard10.png';
import imgBoulevard11 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard11.png';
import imgBoulevard12 from '../assets/images/lp/unidades/listImages//listImgsBoulevard/boulevard12.png';

import { containerListDesktop, containerListMobile, rowList } from '../assets/styles/ListImagesElosCenter.styles';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';


function ListImagesElosCenter() {
   const images = [imgBoulevard1,imgBoulevard2,imgBoulevard3,imgBoulevard4,imgBoulevard5,imgBoulevard6,imgBoulevard7,imgBoulevard8,imgBoulevard9,imgBoulevard10,imgBoulevard11,imgBoulevard12]
  return (
    <section>
        <Container data-aos="fade-down" css={containerListDesktop}>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard1} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard2} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard3} rounded  />
                </Col>
            </Row>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard4} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard5} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard6} rounded  />
                </Col>
            </Row>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard7} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard8} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard9} rounded  />
                </Col>
            </Row>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard10} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard11} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgBoulevard12} rounded  />
                </Col>
            </Row>
        </Container>
        <Container  data-aos="fade-down"  css={containerListMobile}>
            <Row css={rowList}>
                <Col>
                    <Swiper
                     effect='fade'
                     slidesPerView={'auto'}
                     spaceBetween={0}
                     loop={true}
                     pagination={{
                         clickable: true,
                     }}
                     autoplay={{
                         delay: 5000,
                         disableOnInteraction: false,
                       }}
                     mousewheel={true}
                     modules={[Autoplay, Mousewheel, Pagination, Navigation]}
                        className="mySwiper"
                    >
                     {   
                     images.map((img) => {
                        return (
                            <SwiperSlide>
                                <Image src={img} />
                            </SwiperSlide>
                        )
                     })
                     }
                    </Swiper>
                </Col>
            </Row>
        </Container>
    </section>
  );
}

export default ListImagesElosCenter;
