/** @jsx jsx */
import { Swiper, SwiperSlide } from "swiper/react";
import { jsx } from "@emotion/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Pagination } from "swiper";

import ImgSlider1 from '../assets/images/lp/slider/prancheta1.png'
import ImgSlider2 from '../assets/images/lp/slider/prancheta2.png'
import ImgSlider3 from '../assets/images/lp/slider/prancheta3.png'
import ImgSlider4 from '../assets/images/lp/slider/Prancheta4.png'
import ImgSlider5 from '../assets/images/lp/slider/Prancheta5.png'

import ImgSliderMobile1 from '../assets/images/lp/slider/mobile/PranchetaMobile1.png'
import ImgSliderMobile2 from '../assets/images/lp/slider/mobile/PranchetaMobile2.png'
import ImgSliderMobile3 from '../assets/images/lp/slider/mobile/PranchetaMobile3.png'
import ImgSliderMobile4 from '../assets/images/lp/slider/mobile/PranchetaMobile4.png'
import ImgSliderMobile5 from '../assets/images/lp/slider/mobile/PranchetaMobile5.png'

import {
  sliderHome
} from "../assets/styles/SliderHome.styles";
import { Container, Row } from "react-bootstrap";
import { useEffect, useState } from "react";

SwiperCore.use([Pagination]);

const SliderHome = () => {
  const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 500)
      }
      if (typeof window !== 'undefined') {
        setIsMobile(window.innerWidth < 500)
        window.addEventListener('resize', handleResize)
      }
      return () => {
        if (typeof window !== 'undefined') {
          window.removeEventListener('resize', handleResize)
        }
      }
    }, [])

  const carouselOptions = {
    spaceBetween: 0,
    loop: true,
    slidesPerView: 1,
    autoplay: true,
    pagination: {
      // el: "#team-carousel-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      0: {
        spaceBetween: 16,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
      },
      576: {
        spaceBetween: 16,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
      },
      992: {
        spaceBetween: 16,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
      },
      1200: {
        spaceBetween: 16,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
      },
    },
  };

  return (
    <section style={{position: 'relative', overflow: 'hidden'}} css={sliderHome}>
      <Container fluid  >
        <Row>
          <Swiper {...carouselOptions}>
            <SwiperSlide>
              <div>
                <img src={isMobile ? ImgSliderMobile1 : ImgSlider1} alt="banner1" style={{ marginRight: 12, width: '100%' }} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={isMobile ? ImgSliderMobile2 : ImgSlider2} alt="banner2" style={{ marginRight: 12, width: '100%' }} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={isMobile ? ImgSliderMobile3 : ImgSlider3} alt="banner3" style={{ marginRight: 12, width: '100%' }} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={isMobile ? ImgSliderMobile4 : ImgSlider4} alt="banner4" style={{ marginRight: 12, width: '100%' }} />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={isMobile ? ImgSliderMobile5 : ImgSlider5} alt="banner5" style={{ marginRight: 12, width: '100%' }} />
              </div>
            </SwiperSlide>
          </Swiper>
        </Row>
      </Container>
    </section>
  );
};

export default SliderHome;
