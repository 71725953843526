import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import SobreBanner from "@/components/SobreBanner"
import BannerCentroMedianeira from "@/components/BannerCentroMedianeira"
import HeaderOne from "@/components/header-two"
import ListImagesElosCenter from "@/components/ListImagesElosCenter"
import UnidadeMapa from "@/components/UnidadeMapa"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const CentroMedianeiraPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Elos Centro">
          <HeaderOne />
          <SobreBanner paragraph="Do maternal ao Pré-Vestibular" title="Elos Centro"/>
          <BannerCentroMedianeira />
          <ListImagesElosCenter />
          <UnidadeMapa urlMap="https://maps.app.goo.gl/8RNsEU48JXkBDAhz5" instagram="https://www.instagram.com/bertoni.elos?igsh=YzljYTk1ODg3Zg==" title="Do maternal ao Pré-Vestibular" paragraph="Unidade Centro" address="R. Paraguai, 2000, Medianeira - PR" linkMap="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.2257733563642!2d-54.09179580000001!3d-25.2963533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f6ac62fac58d35%3A0xaff6b9c8b5dd8c5d!2sCol%C3%A9gio%20Bertoni%20Elos%20-%20Medianeira!5e0!3m2!1spt-BR!2sbr!4v1736044473849!5m2!1spt-BR!2sbr"/>
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default CentroMedianeiraPage;
 