/** @jsx jsx */
import { css } from "@emotion/react";

export const containerListDesktop = css`
@media (max-width: 700px){
    display: none;
}
`
export const containerListMobile= css`
.col{
    padding-right: 0px;
    padding-left: 0px;
}
@media (min-width: 700px){
    display: none;
}
.swiper, swiper-container {
    padding-bottom: 70px;
}
.swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #4BE66A;
}
.swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 15px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 15px));
}
`
export const rowList = css`
 margin-bottom: 20px;
 img{
    width: 100%;
 }
`
