/** @jsx jsx */
import { css } from "@emotion/react";

export const containerStore = css`
  margin-top: 90px;
  margin-bottom: 90px;
  h1{
    color: #FFF;
    font-family: "normalidad-variable", sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding-bottom: 5px;
    @media (max-width: 1200px){
      font-size: 37px;
    }
    @media (max-width: 992px){
      font-size: 35px;
    }
    @media (max-width: 768px){
      font-size: 30px;
    }
  }
  span{
    color: #FFF;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 1200px){
      font-size: 24px;
    }
    @media (max-width: 992px){
      font-size: 22px;
    }
    @media (max-width: 768px){
      font-size: 20px;
    }
  }
  .unitsLinks{
    color: var(--Green, #4BE66A);
    font-family: "normalidad-variable", sans-serif;
    font-size: 20px;
    padding-top: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    position: relative;
    @media (max-width: 1200px){
      font-size: 19px;
      padding-top: 29px;
    }
    @media (max-width: 992px){
      font-size: 18px;
      padding-top: 28px;
    }
    @media (max-width: 768px){
      font-size: 17px;
      padding-top: 27px;
    }
    ::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;  
      width: 0;
      height: 2px;  
      background-color: #4BE66A;  
      transition: width 0.5s ease-in-out;
    }
    :hover::after {
      width: 48%;
    }
  }
`
export const cardStorecentro = css`
  max-width: 570px;
  max-height: 605px;
  transition: transform .6s ease-in-out, box-shadow .6s ease-in-out; 
  .gradiente {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 29%, rgba(0, 0, 0, 1) 100%);
    border-radius: 30px;
    transition: background-position 0.6s ease-in-out; 
    background-size: 10% 110%; 
    background-position: bottom; 
  }
  .gradiente:hover {
    background-position: top;
  }
  &:hover {
    transform: scale(1.01);
  }
`
export const cardStoreBoulevard = css`
  max-width: 570px;
  max-height: 605px;
  transition: transform .6s ease-in-out, box-shadow .6s ease-in-out; 
  .gradiente {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 29%, rgba(0, 0, 0, 1) 100%);
    border-radius: 30px;
    transition: background-position 0.6s ease-in-out; 
    background-size: 10% 110%; 
    background-position: bottom; 
  }
  .gradiente:hover {
    background-position: top;
  }
  &:hover {
    transform: scale(1.01);
  }
  @media (max-width: 768px){
    padding-top: 50px;
  }
`
export const imagebackground = css`
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
`
export const containerTexts = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 35px;
  z-index: 2;
  bottom: 0;
  @media (max-width: 1200px){
    padding: 33px;
    }
    @media (max-width: 992px){
      font-size: 33px;
    }
    @media (max-width: 768px){
      font-size: 28px;
    }
`
