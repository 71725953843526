import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import SobreBanner from "@/components/SobreBanner"
import SegmentosContent from "@/components/SegmentosContent"
import HeaderOne from "@/components/header-two"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const OrigemPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Segmentos">
          <HeaderOne />
          <SobreBanner title="Segmentos"/>
          <SegmentosContent />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default OrigemPage;
