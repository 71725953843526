/** @jsx jsx */
import { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap"
import "react-modal-video/css/modal-video.min.css"
import { jsx } from '@emotion/react'
import 'aos/dist/aos.css';
import ImgOrigem from '../assets/images/lp/origem/Jump 1.png'
import ImgOrigemBertoni from '../assets/images/lp/origem/Frame 121.svg'
import ImgOrigemBertoniMobile from '../assets/images/lp/origem/Frame 77.svg'
import ImgNomeBertoni from '../assets/images/lp/origem/child.png'

import { 
  cta1,
  cta2,
  cta5,
  imgBox,
  nomeBertoni,
  boxContent,
  DDesktop,
  DMobile
}  from '../assets/styles/SobreContent.styles'

const OrigemPage = () => {
  return (
    <Fragment>
      <div>
        <img css={DMobile} src={ImgOrigem} alt='/' style={{ width: '100%' }} />
      </div>
      <section css={cta1}>
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <div css={boxContent}>
                <h2>Elos que transformam a educação no Sul do Brasil</h2>
                <p> Há 15 anos, um grupo de educadores, inspirados pelo renomado naturalista Moisés Santiago Bertoni, fundaram o Colégio Bertoni com o objetivo de criar uma escola de excelência, que formasse alunos para uma trajetória internacional.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div css={[imgBox, DDesktop]}>
          <img src={ImgOrigem} alt='/' />
        </div>
      </section>
      <section css={cta2}>
        <Container>
          <Row>
            <Col lg={6} sm={12} className="pr-0 pl-0"  >
              <img css={DDesktop} src={ImgNomeBertoni} alt='/' />
            </Col>
            <Col lg={6} sm={12} css={nomeBertoni} >
              <div css={boxContent}>
              <h2>Metodologia atualizada </h2>

                <p>Desde o início, o Bertoni se destacou por sua abordagem inovadora, buscando sempre as melhores práticas pedagógicas do mundo. Com visitas a países como Finlândia, Portugal e Estados Unidos, a escola incorporou metodologias que a colocaram na vanguarda da educação brasileira.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
        <div>
          <img css={DMobile} src={ImgNomeBertoni} alt='/' style={{ width: '100%' }} />
        </div>
      
      <section css={cta5}>
        <Container>
          <Row>
            <Col lg={6} sm={12} data-aos-delay={"500"} data-aos="fade-right">
              <div css={boxContent}>
              <h2>Expansão e Reconhecimento</h2>
                <p>O sucesso do Bertoni, amplamente reconhecido como um dos 50 melhores do país, impulsionou a abertura de unidades em diversas localidades, como Foz, Medianeira, Cascavel, Porto Alegre e Ciudad del Este, no Paraguai.</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div css={[imgBox, DDesktop]} data-aos-delay={"550"} data-aos="fade-left">
          <img src={ImgOrigemBertoni} alt='/' />
        </div>
      </section>
        <div>
          <img css={DMobile} src={ImgOrigemBertoniMobile} alt='/' style={{ width: '100%' }} />
        </div>
    </Fragment>
  );
};

export default OrigemPage;
