/** @jsx jsx */
import React, { useState, useEffect, useContext } from "react";
import { LogoImage, NavLinks } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import { Link } from "gatsby";
import { jsx } from '@emotion/react'
import { 
  header,
  logo,
  mainMenu,
  submenu,
  navigator,
  socialIcon,
  actionsImgIconsSocial,
  socialIconWhatsapp,
  headerMobile,
  actionsImgIconsSocialMobile,
  menuHam,
}  from '../assets/styles/Header.styles'

import Instagram from '../assets/images/lp/icons/instagram-header.svg'
import Facebook from '../assets/images/lp/icons/facebook-header.svg'
import Youtube from '../assets/images/lp/icons/youtube-header.svg'
import Whatsapp from '../assets/images/lp/icons/Whatsapp.svg'

import InstagramMobileHeader from '../assets/images/lp/icons/mobileIconsHeader/instagram-header.svg'
import FacebookMobileHeader from '../assets/images/lp/icons/mobileIconsHeader/facebook-header.svg'
import YoutubeMobileHeader from '../assets/images/lp/icons/mobileIconsHeader/youtube-header.svg'
import WhatsappMobileHeader from '../assets/images/lp/icons/mobileIconsHeader/Whatsapp.svg'

const HeaderTwo = () => {
  const [sticky, setSticky] = useState(false);
  const { searchStatus, updateSearchStatus } = useContext(SearchContext);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const handleSearchClick = e => {
    e.preventDefault();
    updateSearchStatus(!searchStatus);
  };
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <header css={header} dark
      className={`header_01 black_color ${
        true === sticky ? "fixedHeader animated flipInX" : null
      }`}
      id="header"
    >
      <Container className="headerDesktop" >
        <Row >
          <Col className="col-6 d-none d-lg-block" lg={3} md={3} sm={3}>
            <div css={logo}>
              <Link to="/">
                <img src={LogoImage.light} alt="" />
              </Link>
            </div>
          </Col>
          <Col lg={9} sm={8} md={7} className="d-none d-lg-block " >
            <nav css={mainMenu} dark className="text-center">
              <ul style={{display: 'flex', alignContent: 'center', alignItems: 'center',justifyContent: 'space-around' }}>
                {NavLinks.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? "menu-item-has-children"
                          : ""
                      }`}
                    >
                      <Link to={links.url}>{links.name}</Link>
                      {undefined !== links.subItems ? (
                        <ul css={submenu} dark className="sub-menu">
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Col>
          {/* redes sociais  */}
          {/* <Col lg={3} md={4} sm={4} className="d-none d-lg-block socialsContainers" >
            <div className="text-right justify-content-center" >
              <div className="socialitens">
                <a css={actionsImgIconsSocial} href="https://www.instagram.com/bertoni.elos/" target="_blank"><img src={Instagram} /></a>
                <a css={[socialIcon, actionsImgIconsSocial]} href="https://www.facebook.com/bertonielos" target="_blank"><img src={Facebook} /></a>
                <a css={actionsImgIconsSocial} href="https://www.youtube.com/@rede_bertoni/featured" target="_blank"><img src={Youtube} /></a>
                <a css={[socialIconWhatsapp, actionsImgIconsSocial]} href="https://api.whatsapp.com/send/?phone=554588442900&text&type=phone_number&app_absent=0" target="_blank"><img src={Whatsapp} /></a>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
      <Container  fluid css={headerMobile}>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="align-self-center p-0 pl-4 logoMobile">
              <Link to="/">
                <img src={LogoImage.light} alt="" className="img-fluid" />
              </Link>
          </Col>
          {/* <Col className="socialsContainersMobile p-0" >
            <div className="text-right justify-content-center" >
              <div className="socialitensMobile">
                <a css={actionsImgIconsSocialMobile} style={{marginLeft: '9px'}} href="https://www.instagram.com/bertoni.elos/" target="_blank"><img src={InstagramMobileHeader} /></a>
                <a css={actionsImgIconsSocialMobile} style={{marginLeft: '9px'}} href="https://www.facebook.com/bertonielos" target="_blank"><img src={FacebookMobileHeader} /></a>
                <a css={actionsImgIconsSocialMobile} style={{marginLeft: '9px'}} href="https://www.youtube.com/@rede_bertoni/featured" target="_blank"><img src={YoutubeMobileHeader} /></a>
                <a css={actionsImgIconsSocialMobile} style={{marginLeft: '0px'}} href="https://api.whatsapp.com/send/?phone=554588442900&text&type=phone_number&app_absent=0" target="_blank"><img src={WhatsappMobileHeader} /></a>
              </div>
            </div>
          </Col> */}
          <Col xs={1} className="p-0 pr-5">
            <div css={navigator} className="navigator text-right d-block ">
              <a
                id="open-overlay-nav"
                className="menu hamburger"
                onClick={handleMenuClick}
                href="#"
              >
                <i className="mei-menu"></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderTwo;
