/** @jsx jsx */
import { css } from '@emotion/react'

export const sliderHome = css` 
  background: #F5F5F5;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  .swiper-slide {
    width: 60% !important;
    
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .swiper-slide {
    width: 100% !important;
  }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .swiper-slide {
    width: 107% !important;
  }
  }
  
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-left: 88px;
    @media (max-width: 500px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .swiper, swiper-container {
    padding-bottom: 80px;
}

  .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 20px));
    height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 20px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #00854e);
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    padding-bottom: 11px;
}
`

