/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import BertInternational from '../assets/images/lp/origem/bert.svg'
import BertoniPro from '../assets/images/lp/origem/pro.svg'
import BertoniElos from '../assets/images/lp/origem/elos.svg'

import {
  ElosBertoni,
  headerContent,
  pdR,
} from '../assets/styles/EstruturaHome.styles'

const EstruturaHome = () => {
  return (
    <section css={ElosBertoni}>
      <Container>
        <Row
          data-aos-offset='300'
          data-aos='fade-down'
          data-aos-easing='ease-in-out'
          css={headerContent}
        >
          <Col lg={12} md={12} sm={12}>
            <h2>O ELOS FAZ PARTE DA REDE BERTONI</h2>
            <p>o maior grupo educacional do Sul do País, com unidades no RS e no PR.</p>
          </Col>
        </Row>
        <Row>
        <Col css={pdR} lg={4} md={4} sm={12} className='box-school-content'>
          <img src={BertInternational} alt="Avatar" className='image'/>
          <h3>Bert International:</h3>
          <p>Focada em um currículo global, com alta performance em olimpíadas e aprovações em universidades de renome internacional.</p>
        </Col>
        <Col css={pdR} lg={4} md={4} sm={12} className='box-school-content'>
          <img src={BertoniPro} alt="Avatar" className='image'/>
          <h3>Bertoni Pro:</h3>
          <p>Especializado em preparação para os principais vestibulares do país, com um histórico de aprovações invejável.</p>
        </Col>
        <Col css={pdR} lg={4} md={4} sm={12} className='box-school-content'>
          <img src={BertoniElos} alt="Avatar" className='image'/>
          <h3>Bertoni Elos:</h3>
          <p>Destacando-se por uma metodologia alinhada aos desafios do século XXI, com foco em valores e excelência educacional.</p>
        </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EstruturaHome
