/** @jsx jsx */
import { css } from "@emotion/react";

export const containerMapa = css`
    padding: 100px;
    overflow: hidden;
    @media (max-width: 700px){
        padding: 0px;
    }
    h1{
        color: #312E2D;
        font-family: "Normalidad VF" sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; 
        @media (max-width: 700px){
            padding: 0px 0px 0px 16px;
        }
    }
    p{
        color:  #9D9C9B;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        @media (max-width: 700px){
            padding: 5px 0px 0px 5px;
        } 
    }
    img{
        width: 30px;
        height: 30px;
        margin-bottom: 3px;
        @media (max-width: 700px){
            margin: 0px 0px 4px 10px;
        } 
    }
    .address{
        background-color: #F5F5F5;
    }
    .paragraph{
        @media (max-width: 700px){
            padding-left: 16px;
        } 
    }
    .map{
        padding: 0px;
    }
    .text{
        padding: 60px 0px 60px 40px;
        @media (max-width: 700px){
            padding: 60px 0px 20px 0px;
        }
    }
    .btn-success {
        color: #312E2D;
        background-color: #4BE66A;
        border-color: #4BE66A;
        padding: 10px 45px 10px 45px;
        font-size: large;
        font-weight: 700;
        margin-top: 50px;
        border-radius: 0px;
        font-family: "Normalidad VF" sans-serif;
        transition: color .5s ease-in-out, background-color .5s ease-in-out, border-color .5s ease-in-out, box-shadow .5s ease-in-out;
        @media (max-width: 700px){
            margin-bottom: 30px;
        }
    }
    .btn-success:hover{
        color: #fff;
        background-color: #FF501B;
        border-color: #FF501B;
    }
    .btnMobile{
        @media (max-width:700px){
           display: flex;
           align-items: center;
           justify-content: center;
        }
    }
    .instaLink{
        text-decoration: none;
    }
    a {
    color: #9D9C9B;
    text-decoration: none;
    background-color: none;
    cursor: pointer;
    
    }
    a:hover{
        color: #4BE66A;
    }
`

export const actionsImgIcons = css`
img{
  transition: filter 0.3s ease-out;
}
:hover {
    color: #4BE66A;
  img {
    filter: brightness(0) saturate(100%) invert(94%) sepia(27%) saturate(1948%) hue-rotate(56deg) brightness(105%) contrast(80%);
  }
}
:active {
  color: #4BE66A;
  img {
    filter: brightness(0) saturate(100%) invert(94%) sepia(27%) saturate(1948%) hue-rotate(56deg) brightness(105%) contrast(80%);
  }
}
`