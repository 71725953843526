/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Col, Container, Image, Row } from "react-bootstrap";
import imgCentro1 from '../assets/images/lp/unidades/listImages/listImgsJardinEncantado/jardin1.png';
import imgCentro2 from '../assets/images/lp/unidades/listImages/listImgsJardinEncantado/jardin2.png';
import imgCentro3 from '../assets/images/lp/unidades/listImages/listImgsJardinEncantado/jardin3.png';
import imgCentro4 from '../assets/images/lp/unidades/listImages/listImgsJardinEncantado/jardin4.png';

import { containerListDesktop, containerListMobile, rowList } from '../assets/styles/ListImagesJardinEncantado.styles';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Mousewheel, Navigation, Pagination } from 'swiper';


function ListImagesElosCenter() {
   const images = [imgCentro1,imgCentro2,imgCentro3,imgCentro4]
  return (
    <section>
        <Container data-aos="fade-down"  css={containerListDesktop}>
            <Row css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgCentro1} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro2} rounded  />
                </Col>
                <Col xs={12} md={4}>
                    <Image src={imgCentro3} rounded  />
                </Col>
            </Row>
            <Row className='d-flex justify-content-center' css={rowList}>
                <Col xs={12} md={4}>
                    <Image src={imgCentro4} rounded  />
                </Col>
            </Row>
        </Container>
        <Container  data-aos="fade-down"  css={containerListMobile}>
            <Row css={rowList}>
                <Col>
                    <Swiper
                     effect='fade'
                     slidesPerView={'auto'}
                     spaceBetween={0}
                     loop={true}
                     pagination={{
                         clickable: true,
                     }}
                     autoplay={{
                         delay: 5000,
                         disableOnInteraction: false,
                       }}
                     mousewheel={true}
                     modules={[Autoplay, Mousewheel, Pagination, Navigation]}
                        className="mySwiper"
                    >
                     {   
                     images.map((img) => {
                        return (
                            <SwiperSlide>
                                <Image src={img} />
                            </SwiperSlide>
                        )
                     })
                     }
                    </Swiper>
                </Col>
            </Row>
        </Container>
    </section>
  );
}

export default ListImagesElosCenter;
