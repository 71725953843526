/** @jsx jsx */
import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import MaskInput from 'react-awesome-mask-input';

import {
  contatosHome,
  formVisitas,
  formContatos,
  btnEnviar2,
  visits,
} from '../assets/styles/ContatosHome.styles'
import { useEffect, useState } from 'react';

const ContatosHome = () => {
  const [showDropdownData, setShowDropdownData] = useState(false);
  const [showDropdownTime, setShowDropdownTime] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');
  const [formattedTime, setFormattedTime] = useState('');
  const [unit, setUnit] = useState('');
  const [isChecked, setIsChecked] = useState(false);  

  const units = ['Centro, Medianeira','Jardim Encantado, Medianeira','Boulevard, Foz do Iguaçu']

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);  
  };
   

  const handleToggle1 = (isOpen, event) => {
    if (event?.target?.type === 'date') return; 
    setShowDropdownData(isOpen);
  };

  
  const handleToggle2 = (isOpen, event) => {
    if (event?.target?.type === 'time') return; 
    setShowDropdownTime(isOpen);
  };

  const handleDateChangeDate = (event) => {
    const date = event.target.value; 
    const [year, month, day] = date.split('-'); 
    setFormattedDate(`${day}/${month}/${year}`); 
    setShowDropdownData(false);
  };

  const handleDateChangeHour = (event) => {
    const hours = event.target.value; 
    const [hour, min] = hours.split(':'); 
    setFormattedTime(`${hour}:${min}`); 
    setShowDropdownData(false);
  };

  const handleDateChangeUnit = (selectedUnit) => {
    setUnit(selectedUnit);
  };
  
  return (
      <Container id="contatoUnidades" css={contatosHome} fluid >
        <Row>
          <Col
            lg={6}
            md={6}
            sm={12}
          >
            <div css={formVisitas}></div>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
          >
            <div  css={formContatos}>
              <h2>Contato</h2>
              <p >
              Preencha o formulário e solicite o contato com nossa central de relacionamento
              </p>
              <form
                name='contatos'
                method='POST'
                action='/sucesso'
                data-netlify='true'
              >
                <input
                  type='hidden'
                  name='form-name'
                  value='contatos'
                />
                <p>
                  <input 
                    type='text' 
                    name='name' 
                    placeholder='Nome' 
                    required 
                  />
                </p>
                <p>
                  <MaskInput
                    type="text"
                    mask={['(99) 9999-9999', '(99) 9 9999-9999']}
                    placeholder="Telefone"
                    name='telefone'
                  />
                </p>
                <p>
                  <input
                    type='mail'
                    name='email'
                    placeholder='E-mail'
                    required
                  />
                </p>
                  <Dropdown css={visits} className='unitVisit pb-3'>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <p>{!unit ? 'Unidades' : unit}</p>
                      </Dropdown.Toggle>

                      <Dropdown.Menu >
                        {units.map((unit,index) => {
                          return (
                            <Dropdown.Item  >
                              <p   onClick={() => handleDateChangeUnit(unit)}>{unit}</p>
                            </Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                <textarea
                  name='mensagem'
                  rows='4'
                  cols='50'
                  placeholder='Mensagem'
                />
                <label className="container">Desejo agendar uma visita
                  <input
                   checked={isChecked}  
                   onChange={handleCheckboxChange}  
                   type="checkbox" 
                   />
                  <span className="checkmark"></span>
                </label>
                {!isChecked && <button css={btnEnviar2} type='submit'>
                  Quero ser Elos
                </button>}
                {isChecked &&
                <div css={visits}>
                  <div style={{display:'flex' }} >
                    <Dropdown show={showDropdownData} onToggle={handleToggle1} className='dateVisit'>
                      <Dropdown.Toggle variant="success" id="dropdown-custom" className="custom-dropdown">
                      {!formattedDate ? 'Data': formattedDate}
                      </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item as="div">
                          <form >
                              <div className="nativeDatePicker">
                                <input
                                  style={{width:'100%'}}
                                  type="date"
                                  id="bday"
                                  name="bday"
                                  onChange={handleDateChangeDate} 
                                />
                                <span className="validity down"></span>
                              </div>
                            </form>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown >

                    <Dropdown show={showDropdownTime} className='hourVisit' onToggle={handleToggle2}>
                      <Dropdown.Toggle variant="success" id="dropdown-custom">
                        {!formattedTime ? 'Hora' : formattedTime}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={(e) => e.stopPropagation()} href="#/action-1">
                            <input 
                              style={{width:'100%'}}
                              onClick={(e) => e.stopPropagation()} 
                              type="time" 
                              id="appt" 
                              name="appt" 
                              min="09:00" 
                              max="18:00" 
                              onChange={handleDateChangeHour} 
                              required />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <button css={btnEnviar2} type='submit'>
                    Quero ser Elos
                  </button>
                </div>
                }
          
              </form>
            </div>
          </Col>
        </Row>
      </Container>
  )
}

export default ContatosHome
