/** @jsx jsx */
import { css } from '@emotion/react'
import ImgPreVestibular from '../images/lp/contatos/contato.png'


export const contatosHome = css` 
  background: #ffffff;
  font-family: "normalidad-variable", sans-serif;
  width: 100%;
  #formulario{
    scroll-margin-top: 224px;
  }
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 0px;
    padding-left: 0px;
  }
   
`

export const formVisitas = css` 
 background: url(${ImgPreVestibular}) #4BE66A no-repeat center center / cover ;
  width: 100%;
  height: 100%;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 24px;
    display: none;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-bottom: 24px;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    margin-bottom: 24px;
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
`

export const btnEnviar = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;
  font-variation-settings: "wght" 700;
  background: #F7FF53;
  width: 118px;
  height: 52px;
  border: none;
  margin-top: 65px;
  :hover {
    color: #ffffff;
    background: #312E2D;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: auto;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: auto;
  }
`

export const formContatos = css` 
  background: #4BE66A;
  padding: 90px 170px 90px 90px;
  h2 {
    width: 100%;
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #00854E;
    margin-bottom: 26px;
    font-variation-settings: "wght" 130, "wght" 600;
    width: 100%;
  }
  p {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #00854E;
  }
  form {
    width: 100%;
    font-family: "normalidad-variable", sans-serif;
    input {
      border: 2px solid #00854E;
      width: 100%;
      height: 38px;
      background: #4BE66A;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #00854E ;
      padding-left: 24px;
      padding-right: 24px;
      &::placeholder {
        color: #00854E;
      }
    }
    
    textarea {
      width: 100%;
      background: #4BE66A;
      font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #00854E;
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 10px;
      margin-bottom: 13px;
      border: 2px solid #00854E;
      &::placeholder {
        color: #00854E;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    h2 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  padding-bottom: 0;
  color: #00854E;
  font-family: "Normalidad VF",sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  }
  .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 2px solid #00854E;
  background-color: #4BE66A;
  }
  .container:hover input ~ .checkmark {
  background-color: #4BE66A;
}

.container input:checked ~ .checkmark {
  background-color: #F7FF53;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 6px;
  top: 0px;
  width: 8px;
  height: 17px;
  border: solid #00854E;
  border-width: 0 2.8px 2.8px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  overflow: hidden;
}

@media (max-width: 500px){
  padding: 50px 15px 50px 15px;
  h2 {
      text-align: left;
    }
    p {
      text-align: left;
    }
}
`

export const btnEnviar2 = css`
margin-top: 15px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #312E2D;
  font-variation-settings: "wght" 700;
  background: #F7FF53;
  width: 186px;
  height: 52px;
  border: none;
  :hover {
    color: #ffffff;
    background: #00854E;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
  }
`

export const visits = css`
display: flex;
flex-direction: column;
.dateVisit{
  margin-top: 10px;
  width: 100%;
}

.hourVisit{
  margin-top: 10px;
  margin-left: 50px;
  width: 100%;
  @media (max-width: 500px){
    margin-left: 30px;
  }
}

.unitVisit{
  margin-top: 15px;
  width: 100%;
}

.btn-success {
    color: #00854E;
    padding: 8px   ;
    background-color: #4BE66A;
    border: 2px solid #00854E;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.btn-success:hover {
    color: #00854E;
    background-color: #4BE66A;
    border: 2px solid #00854E;
}

.dropdown-menu {
  padding: 0;
}
.dropdown-item {
  padding: 0;
}
 
.dropdown-toggle::after {
  border: solid #00854E;
  margin-left: 45px;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 6px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #00854E;
   background-color: #4BE66A;
   border-color: #00854E;
}
.btn-success.focus, .btn-success:focus {
    color: #00854E;
    background-color: #4BE66A;
    border-color: #00854E;
}
p{
  width: 100%;
    font-family: 'Montserrat', sans-serif;
    color: #00854E;
    font-weight: 600;
      font-size: 18px;
      line-height: 120%;
      color: #00854E ;
      margin-bottom: 0px;
      text-align: left;
      padding-left: 16px ;
}
`