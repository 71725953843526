/** @jsx jsx */
import { Swiper, SwiperSlide } from "swiper/react";
import { jsx } from "@emotion/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay, Keyboard, Navigation, Pagination } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import { btnKnowUniforms, carouselStoreSwipe } from "../assets/styles/StoreCarousel.styles";
import { Link } from "gatsby";

import ImgSlider1 from '../assets/images/lp/store/carrouselStore/carrousel1.png'
import ImgSlider2 from '../assets/images/lp/store/carrouselStore/carrousel2.png'
import ImgSlider3 from '../assets/images/lp/store/carrouselStore/carrousel3.png'
import ImgSlider4 from '../assets/images/lp/store/carrouselStore/carrousel4.png'
import ImgSlider5 from '../assets/images/lp/store/carrouselStore/carrousel5.png'
import { useEffect, useState } from "react";

SwiperCore.use([Pagination]);

const SliderHome = () => {
    const [isMobile, setIsMobile] = useState(false)
        useEffect(() => {
          const handleResize = () => {
            setIsMobile(window.innerWidth < 600)
          }
          if (typeof window !== 'undefined') {
            setIsMobile(window.innerWidth < 600)
            window.addEventListener('resize', handleResize)
          }
          return () => {
            if (typeof window !== 'undefined') {
              window.removeEventListener('resize', handleResize)
            }
          }
        }, [])
  return (
    <section style={{position: 'relative', overflow: 'hidden',paddingBottom: '50px'}} css={carouselStoreSwipe}>
      <Container  >
        <Row className="pb-5 pt-5">
          <Col data-aos="fade-right"  data-aos-duration="1500"
     data-aos-easing="ease-in-sine" className="containerTexts order-2 order-md-1">
            <h1>Educação e estilo de alto nível</h1>
            <p>A combinação perfeita de conforto e criatividade que só quem veste Elos conhece.</p>
            <Link css={btnKnowUniforms} to='/store'>Conhecer uniformes</Link>
          </Col>
          <Col data-aos="fade-left"  data-aos-duration="1500"
     data-aos-easing="ease-in-sine" md={8} className="slideCarrousel  order-1 order-md-2">
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={isMobile ? 0 : 30}
              loop={true}
              keyboard={{
                enabled: true,
              }}
              pagination={{
                  clickable: true,
              }}
              autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
              mousewheel={true}
              modules={[Autoplay,Keyboard, Pagination, Navigation]}
                 className="mySwiper"

            >
              <SwiperSlide >
                <div className="containerImg" >
                  <img src={ImgSlider1} alt="banner1" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider2} alt="banner2" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider3} alt="banner3" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider4} alt="banner4" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider5} alt="banner5" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider1} alt="banner1" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider2} alt="banner2" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider3} alt="banner3" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider4} alt="banner4" className="itemImg" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="containerImg" >
                  <img src={ImgSlider5} alt="banner5" className="itemImg" />
                </div>
              </SwiperSlide>
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SliderHome;
