import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import SobreBanner from "@/components/SobreBanner"
import BannerBoulevard from "@/components/BannerBoulevard"
import ListImagesBoulevard from "@/components/ListImagesBoulevard"
import UnidadeMapa from "@/components/UnidadeMapa"
import HeaderOne from "@/components/header-two"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const BoulevardPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Elos Boulevard">
          <HeaderOne />
          <SobreBanner paragraph="Ensino Médio e Pré-vestibular" title=" Elos Boulevard"/>
          <BannerBoulevard />
          <ListImagesBoulevard />
          <UnidadeMapa urlMap="https://maps.app.goo.gl/EUQopYxGnvYXtT64A" instagram="https://www.instagram.com/elos.boulevard?igsh=YzljYTk1ODg3Zg==" numberFone="554588424675" title="Ensino Médio e Pré-vestibular" paragraph="Unidade Elos Boulevard" address="Av. das Cataratas, 1118, Foz do Iguaçu - PR" linkMap="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7199.098519479505!2d-54.569665!3d-25.553387!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94f69113e43132c7%3A0x79abe02f6e40935c!2sBertoni%20Elos%20Boulevard!5e0!3m2!1spt-BR!2sbr!4v1736282897456!5m2!1spt-BR!2sbr"/>
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default BoulevardPage;

 