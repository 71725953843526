import React from "react"
import Footer from "@/components/footer"
import Layout from "@/components/layout"
import BannerStore from "@/components/BannerStore"
import SectionStore from "@/components/SectionStore"
import HeaderOne from "@/components/header-two"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"

const storePage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Lojas Elos">
          <HeaderOne />
          <BannerStore />
          <SectionStore />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default storePage;
